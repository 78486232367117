import React from 'react';
import { withRouter } from 'react-router-dom';
import authenticationService from '../auth/AuthenticationService';


class LoginPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            disabled: false,
            mobile: '',
            otp: '',
            hideOtp: true,
            errorMobile: false,
            errorOtp: false,
            currentCount: 120,
            intervalId: null,
            otpError: null
        };

        // redirect to home if already logged in
        if (authenticationService.isAuthenticated()) {
            this.props.history.push('/');
        }
    }

    updateMobile(value) {
        this.setState({
            mobile: value,
        });
    }

    updateOtp(value) {
        this.setState({
            otp: value
        })
    }

    sendOtp() {

        this.setState({
            errorMobile: false,
            errorOtp: false,
            otpError: null,
            currentCount: 120,
        });
        if (this.isValidMobile()) {
            this.setState({
                disabled: true,
                hideOtp: false,
            });

            this.intervalId = setInterval(this.timer.bind(this), 1000);
        
        authenticationService.sendOtp(this.state.mobile)
            .then(
                otp => {
                    console.log(otp.data)
                    if (!otp.data.sucess) {
                        this.setState({
                            otpError: otp.data.error_message,
                            disabled: false,
                            hideOtp: true,
                        });

                        clearInterval(this.intervalId);
                    }
                },
                error => {
                    // setSubmitting(false);
                    // setStatus(error);
                    console.log(error);
                }
            );
        }
    }

    timer() {
        this.setState({
            currentCount: this.state.currentCount - 1
        })

        if (this.state.currentCount < 1) {
            this.setState({
                disabled: false,
            });
            clearInterval(this.intervalId);
        }
    }

    componentWillUnmount() {
        clearInterval(this.intervalId);
    }

    isValidMobile() {
        const reg = /^[0]?[789]\d{9}$/;
        if (this.state.mobile == null || this.state.mobile.trim().length === 0 || reg.test(this.state.mobile) === false) {
            this.setState({
                errorMobile: true
            });
            return false;

        }
        return true;
    }

    isValidOtp() {
        if (this.state.otp == null || this.state.otp.trim().length === 0) {
            this.setState({
                errorOtp: true
            });
            return false;
        }
        return true;
    }

    login() {
        this.setState({
            errorMobile: false,
            errorOtp: false
        });
        let validMobile = this.isValidMobile();
        let validOtp = this.isValidOtp();
        console.log(validMobile);
        if (validOtp && validMobile) {
            authenticationService.login(this.state.mobile, this.state.otp)
                .then(
                    user => {
                        // console.log(user);
                        const { from } = this.props.location.state || { from: { pathname: "/" } };
                        this.props.history.push(from);
                    },
                    error => {
                        // setSubmitting(false);
                        // setStatus(error);
                        if (error === 'error.http.401') {
                            this.setState({
                                otpError: 'Invalid Otp',
                                disabled: false,
                            });
                        }
                    }
                );
        }
        // this.props.history.push('/'+this.props.location);
    }

    render() {
        return (
            <div>
                <div className={(this.state.otpError != null ? "show" : "hidden") + " alert alert-danger "} >
                    <strong>Error!</strong> {this.state.otpError}.
                </div>
                <input
                    type="text"
                    onBlur={(e) => { this.updateMobile(e.target.value) }}
                    className={'form-control' + (this.state.errorMobile ? ' is-invalid' : '')}
                    placeholder="Mobile Number"
                />
                {this.state.errorMobile ? <div className="invalid-feedback">
                    Please Enter Mobile No
                 </div> : null}
                <br />
                <button disabled={this.state.disabled} className="btn btn-success" onClick={this.sendOtp.bind(this)} >Send OTP</button>
               &nbsp;  <span className={this.state.disabled ? "show" : "hidden"}>{this.state.currentCount}</span>
                <br />
                <br />
                <div className={this.state.hideOtp ? "hidden" : "show"}>
                    <input
                        type="text"
                        onBlur={(e) => { this.updateOtp(e.target.value) }}
                        className={'form-control' + (this.state.errorOtp ? ' is-invalid' : '')}
                        placeholder="OTP"
                    />
                    {this.state.errorOtp ? <div className="invalid-feedback">
                        Please Enter OTP
                 </div> : null}

                    <br />
                    <button className="btn btn-success" onClick={this.login.bind(this)} >Login</button>
                </div>
                {/* <div className="alert alert-info">
                    Username: test<br />
                    Password: test
                </div> */}
                {/* <h2>Login</h2>
                <Formik
                    initialValues={{
                        username: '',
                        password: ''
                    }}
                    validationSchema={Yup.object().shape({
                        username: Yup.string().required('Mobile No is required'),
                        password: Yup.string().required('Password is required')
                    })}
                    onSubmit={({ username, password }, { setStatus, setSubmitting }) => {
                        setStatus();
                        authenticationService.login(username, password)
                            .then(
                                user => {
                                    const { from } = this.props.location.state || { from: { pathname: "/" } };
                                    this.props.history.push(from);
                                },
                                error => {
                                    setSubmitting(false);
                                    setStatus(error);
                                }
                            );
                    }}
                    render={({ errors, status, touched, isSubmitting }) => (
                        <Form>
                            <div className="form-group">
                                <label htmlFor="username">Mobile No</label>
                                <Field name="username" type="text" className={'form-control' + (errors.username && touched.username ? ' is-invalid' : '')} />
                                <ErrorMessage name="username" component="div" className="invalid-feedback" />
                            </div>
                            <div> <button className="btn btn-success" onClick={this.sendOtp} > Send Otp </button>  </div>
                            <div className="form-group">
                                <label htmlFor="password">Password</label>
                                <Field name="password" type="password" className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')} />
                                <ErrorMessage name="password" component="div" className="invalid-feedback" />
                            </div>
                            <div className="form-group">
                                <button type="submit" className="btn btn-primary" disabled={isSubmitting}>Login</button>
                                {isSubmitting &&
                                    <img alt="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                }
                            </div>
                            {status &&
                                <div className={'alert alert-danger'}>{status}</div>
                            }
                        </Form>
                    )}
                /> */}
            </div>
        )
    }
}

export default withRouter(LoginPage);