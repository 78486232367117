import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { handleResponse } from '../auth/handle-response';
import { url } from '../config/config';
import authenticationService from '../auth/AuthenticationService';



class Items extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: null,
      items: null
    }
    this._filterUpdated = this._filterUpdated.bind(this);
  }

  async componentDidMount() {
    const { match: { params } } = this.props;
    this.state.supplier = params.supplier;
    this.state.group = params.id;
    this.setState({});
    await axios.get(`${url}supplier/groupIts`, {
      params: {
        'key': authenticationService.getAuthToken(),
        "supplier": params.supplier, 'groupId': params.id
      }
    }).then(handleResponse)
      .then(res => {
        // this.state.data = res;
        this.setState({
          data: res,
          items: res
        });
        // this.setState({});
      });

  }

  _filterUpdated(newData, filtersObject) {
    this.setState({
      data: newData,
    });
  }

  search(value) {
    if (value) {
      const data = this.state.items.filter(i => i.itemName.toLowerCase().includes(value)
        || i.typeName.toLowerCase().includes(value) || i.styleName.toLowerCase().includes(value));

      this.setState({
        data: data
      });
    } else {
      const items = this.state.items;
      this.setState({
        data: items
      });
    }
  }

  render() {
    // const items = this.state.items;


    return (
      <div className="container">
        <div className="row">
          <div className="container">
            <ol className="breadcrumb breadcrumb-arrow">
              <li><Link to={`/`}> Home </Link> </li>
              <li> <Link to={`/group/${this.state.supplier}`}> Group </Link> </li>
              <li className="active"><span>Items</span></li>
            </ol>
          </div>

          <div className="my-3 p-3 bg-white rounded shadow-sm col-md-12 ">
            {this.state.data === null && <p>Loading Items...</p>}
            {this.state.data &&
              <div>
                <div className="row">
                  <div className="col-md-12 col-12">
                    <div className="input-group">
                      <span className="input-group-addon"><i className="glyphicon glyphicon-search"></i></span>
                      <input type="text" className="form-control" placeholder="Search" onChange={(e) => { this.search(e.target.value) }} />
                    </div>
                  </div>

                </div>
                <br />
                <table className="table table-striped table-responsive">
                  <thead>
                    {/* <TableFilter rows={this.state.data} 
              onFilterUpdate={this._filterUpdated}> */}
                    <tr>
                      <th>Image</th>
                      <th>Item</th>
                      <th>Type</th>
                      <th>Style</th>
                      <th>Rate</th>
                      <th>Moq</th>
                      <th>Action</th>
                    </tr>
                    {/* </TableFilter> */}
                  </thead>
                  <tbody>
                    {
                      this.state.data.map(item => (
                        <tr key={item.tranId}>
                          <td className="w-10">{item.images && item.images[0] && <img src={item.images[0].thumbPath} alt="item" className="img-thumbnail" />} </td>
                          <td>{item.itemName}</td>
                          <td>{item.typeName}</td>
                          <td>{item.styleName}</td>
                          <td>{item.rate}</td>
                          <td>{item.qty}</td>
                          <td>
                            <Link to={{ pathname: `/group/${this.state.supplier}/${this.state.group}/${item.tranId}/edit`, data: item }}> <button className="btn btn-success">
                              Edit  </button> </Link>

                            {/* <div className="col-md-1 col-2"> */}
                            <Link to={{ pathname: `/group/${this.state.supplier}/${this.state.group}/${item.item_id}/new`, data: item }}>
                              <button className="btn btn-outline-primary"> Add </button> </Link>
                            {/* </div> */}
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>
            }
          </div>

        </div>
      </div>
    );
  }
}

export default Items;