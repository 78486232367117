import React from 'react';

import { Link } from 'react-router-dom';
import { store } from 'react-notifications-component';
import axios from 'axios';
import { url } from '../config/config';
import authenticationService from '../auth/AuthenticationService';
import { handleResponse } from '../auth/handle-response';


class NewItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            item: "",
            type: "",
            style: "",
            color: "",
            rate: 1,
            moq: 1,
        }
    }

    saveStateToLocalStorage(e) {
        localStorage.setItem("new_item", JSON.stringify(this.state.data));
    }

    removeStateFromLocal() {
        localStorage.removeItem("new_item");
    }

    componentWillUnmount() {
        window.removeEventListener(
            "beforeunload",
            this.saveStateToLocalStorage.bind(this)
        );

        this.removeStateFromLocal();
    }
    async componentDidMount() {
        const { match: { params } } = this.props;
        this.state.supplier = params.supplier;
        this.state.group = params.group;
        const { data } = this.props.location;
        this.state.data = data != null ? data : JSON.parse(localStorage.getItem("new_item")) || null;
        this.state.item = this.state.data.itemName;
        this.setState({});
        window.addEventListener(
            "beforeunload",

            this.saveStateToLocalStorage.bind(this)
        );
    }
    render() {
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <div className="container">
                            <ol className="breadcrumb breadcrumb-arrow">
                                <li><Link to={`/`}> Home </Link> </li>
                                <li> <Link to={`/group/${this.state.supplier}`}> Group </Link> </li>
                                <li><Link to={`/group/${this.state.supplier}/${this.state.group}`}> Items </Link> </li>
                                <li className="active"><span>New</span></li>
                            </ol>
                        </div>
                    </div></div>

                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-10">
                            <div className="my-3 p-3 bg-white rounded shadow-sm col-md-12 ">
                                <form onSubmit={(e) => { this.btnSave(e) }}>
                                    <div className="row justify-content-center">
                                        <div className="col-md-12" style={{ textAlign: "center" }}>
                                            <h3> Create ITS</h3>
                                        </div>
                                    </div>
                                    <div className="form-row row">
                                        <div className="col-md-4 mb-3">
                                            <label>Item Name</label>
                                            <input type="text" className="form-control" placeholder="Item Name" value={this.state.item} readOnly required />
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <label>Type</label>
                                            <input type="text" className="form-control" placeholder="Type" required
                                                value={this.state.type} onChange={(e) => { this.changeType(e.target.value) }} />
                                        </div>

                                        <div className="col-md-4 mb-3">
                                            <label>Style</label>
                                            <input type="text" className="form-control" placeholder="Style" required
                                                value={this.state.style} onChange={(e) => { this.changeStyle(e.target.value) }} />
                                        </div>
                                    </div>
                                    <div className="form-row row">
                                        <div className="col-md-4 mb-3">
                                            <label>Rate</label>
                                            <input type="number" className="form-control" placeholder="Rate" required
                                                value={this.state.rate} onChange={(e) => { this.changeRate(e.target.value) }} />
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <label>MOQ</label>
                                            <input type="number" className="form-control" placeholder="MOQ" min="1" required readOnly
                                                    value={this.state.data?.qty ?? 0}  />
                                        </div>

                                        <div className="col-md-4 mb-3">
                                            <label>Color</label>
                                            <input type="text" className="form-control" placeholder="Color"
                                                value={this.state.color} onChange={(e) => { this.changeColor(e.target.value) }} />
                                        </div>
                                    </div>
                                    <div className="form-row row">
                                        <div className="col-md-6 mb-3 col-12">
                                            <button className="btn btn-outline-danger btn-block" type="reset"
                                                onClick={this.reset.bind(this)} > Reset</button>
                                        </div>

                                        <div className="col-md-6 mb-3 col-12">
                                            <button className="btn btn-outline-success btn-block" type='submit'
                                            > Save</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    showErrorMessage(value) {
        store.addNotification({
            title: "Error!",
            message: value,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
                duration: 5000,
                onScreen: true
            }
        });
    }

     btnSave(e) {
        
        e.preventDefault()
        if (this.state.moq == 0) {
            this.showErrorMessage("Moq Cannot be Zero");
            e.preventDefault()
            return;
        }
        if (this.state.rate == 0) {
            this.showErrorMessage("Rate Cannot be Zero");
            e.preventDefault()
            return;
        }
       
       this.sendCreate();
    }

    async sendCreate(){
        await axios.post(`${url}supplier/item/${this.state.data.item_id}`, null, {
            params: {
                'key': authenticationService.getAuthToken(),
                "supplier": parseInt(this.state.supplier),
                "rate": this.state.rate,
                "moq": this.state.moq,
                "type": this.state.type,
                "style": this.state.style,
                "color": this.state.color
            }
        }).then(handleResponse)
            .then(res => {

                store.addNotification({
                    title: "Success!",
                    message: "Item Type Style Create Success",
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
                this.reset();
            }, error => {
                store.addNotification({
                    title: "Error!",
                    message: error.response.data.error || "Error on Update Record",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });

            });
    }

    changeColor(value) {
        this.setState({
            color: value
        })
    }

    changeMOQ(value) {
        this.setState({
            moq: value < 0 ? 1 : value
        })
    }

    changeRate(value) {
        this.setState({
            rate: value < 0 ? 1 : value
        })
    }

    changeStyle(value) {
        this.setState({
            style: value
        })
    }
    changeType(value) {
        this.setState({
            type: value
        })
    }

    reset() {
        this.state.type = "";
        this.state.style = "";
        this.state.rate = 1;
        this.state.moq = 1;
        this.state.color = "";
        this.setState({});
    }
}


export default NewItem;