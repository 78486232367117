import React from 'react';
import './App.css';
import HeaderNavBar from './navbar/NavBar';
// import LoginModel from './components/LoginModel';
// import NoItem from './components/defaultNoItem';
// import LoginPage from './components/LoginPage';
// import authenticationService from './auth/AuthenticationService';
import Groups from './components/Groups';
import Items from './components/Items';
import { SecuredRoute } from './auth/SecuredRoute';
import { Route } from 'react-router';
import LoginView from './components/LoginView';
import { useLocation } from 'react-router-dom'
import Suppliers from './components/Suppliers';
import EditItem from './components/EditItem';
import FooterBar from './navbar/FooterBar';
import NewItem from './components/NewItem';


function App() {
  // location 
  var bg = ["loginbg_img", "loginbg_img1", "loginbg_img2"];
  return (
    <div className={ useLocation().pathname === '/login'? "loginbg " + bg[Math.floor(Math.random() * bg.length)] : null } >
      <div className="main">
      <HeaderNavBar />
      <div className="container " style={{marginTop: 50}}>
        {/* <div className="row"> */}
          {/* <div className="col-sm-12"> */}
             {/* { authenticationService.isAuthenticated() && .pathname !== '/login' ? null  : <NoItem />  }  */}
          {/* </div> */}
        {/* </div> */}
      </div>
      {/* <div className=""> */}
      {/* <Switch> */}
        {/* <Route path */}
      <SecuredRoute exact path="/" component={Suppliers} />
      <SecuredRoute exact path='/group/:id' component={Groups} />
      <SecuredRoute exact path='/group/:supplier/:id' component={Items} />
      <SecuredRoute path='/group/:supplier/:group/:item/edit' component={EditItem} />
      <SecuredRoute path='/group/:supplier/:group/:itemId/new' component={NewItem} />
      <Route path='/login' component={LoginView} />
      {/* </Switch> */}
      </div>
      <FooterBar />
    </div>
  );
}

export default App;
