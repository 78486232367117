import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { handleResponse } from '../auth/handle-response';
import { url } from '../config/config';
import authenticationService from '../auth/AuthenticationService';


class Groups extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      items: null,
      supplier: 0
    }
  }

  async componentDidMount() {
    const { match: { params } } = this.props;
    this.state.supplier = params.id;
    await axios.get(`${url}supplier/groups`, { params: { 'key': authenticationService.getAuthToken(), "supplier": params.id } }).then(handleResponse)
      .then(res => {
        this.setState({
          items: res,
        });
      });

  }

  render() {
    return (
      <div className="container">
        <div className="row">
        <div className="container">
            <ol className="breadcrumb breadcrumb-arrow">
              <li><Link to={`/`}> Home </Link> </li>
              <li className="active"><span>Group</span> </li>
            </ol>
          </div>
          {this.state.items === null && <p>Loading groups...</p>}
          {
            this.state.items && this.state.items.map(item => (
              <div key={item.id} className="col-sm-12 col-md-4 col-lg-3">
                <Link to={`/group/${this.state.supplier}/${item.id}`}>
                  <div className="card text-white bg-success mb-3">
                    {/* <div className="card-header">Answers: {item.name}</div> */}
                    <div className="card-body">
                      <h4 className="card-title">{item.name}</h4>
                      {/* <p className="card-text">{item.description}</p> */}
                    </div>
                  </div>
                </Link>
              </div>
            ))
          }
        </div>
      </div>
    );
  }
}

export default Groups;