import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { handleResponse } from '../auth/handle-response';
import { url } from '../config/config';
import authenticationService from '../auth/AuthenticationService';

class Suppliers extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            items: null
        }
    }

    async componentDidMount() {
        await axios.get(`${url}supplier/suppliers`, { params: { 'key': authenticationService.getAuthToken() } }).then(handleResponse)
            .then(res => {
                this.setState({
                    items: res,
                });
            });

        // this.setState({
        //   items : [
        //     {
        //       id:1,
        //       name:'Finished Goods'
        //     },
        //     {
        //       id:2,
        //       name:'Yarn'
        //     },
        //     {
        //       id:3,
        //       name:'Other'
        //     },
        //     {
        //       id:4,
        //       name:'Abc'
        //     },
        //     {
        //       id:5,
        //       name:'aaaa'
        //     }
        //   ]
        // });
    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    
                    {this.state.items === null && <p>Loading Suppliers...</p>}
                    {
                        this.state.items && this.state.items.map(item => (
                            <div key={item.id} className="col-sm-12 col-md-4 col-lg-3">
                                <Link to={`/group/${item.id}`}>
                                    <div className="card text-white bg-success mb-3">
                                        {/* <div className="card-header">Answers: {item.name}</div> */}
                                        <div className="card-body">
                                            <h4 className="card-title">{item.name}</h4>
                                            {/* <p className="card-text">{item.description}</p> */}
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        ))
                    }
                </div>
            </div>
        );
    }
}

export default Suppliers;