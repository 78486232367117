import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import { Navbar}   from 'react-bootstrap';
// import LoginModel from '../components/LoginModel';
import authenticationService from '../auth/AuthenticationService';

function HeaderNavBar(props) {
  const signOut = () => {
    authenticationService.signOut();
    props.history.replace('/login');
  };

  return (
    <Navbar bg="dark" variant="dark">
    <Navbar.Brand href="#home">
   
    </Navbar.Brand>
      <Link className="navbar-brand" to="/">
        Accountsdeck Supplier
      </Link>

     <Navbar.Collapse className="justify-content-end">
    <Navbar.Text>
      {
        // !authenticationService.isAuthenticated() &&
        // <LoginModel />
      }
      {
        authenticationService.isAuthenticated() &&
        <div> 
           <label className="mr-2 text-white">{authenticationService.getMobileNo()}</label>
          <button className="btn btn-dark" onClick={() => {signOut()}}>Sign Out</button>
        </div>
      }
    </Navbar.Text>
  </Navbar.Collapse>
    </Navbar>
  );
}

export default  withRouter(HeaderNavBar);