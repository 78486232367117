import React from 'react'
import { Link } from 'react-router-dom';
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader';
import authenticationService from '../auth/AuthenticationService';
import { url as urlVal } from '../config/config';
import Bootbox from 'react-bootbox';
import axios from 'axios';
import { handleResponse } from '../auth/handle-response';
import { url } from '../config/config';
import { store } from 'react-notifications-component';


class EditItem extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            item: null,
            delImage: null,
            show: false,
            rate: 0,
            moq: 0,
        }
    }

    saveStateToLocalStorage(e) {
        // e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
        // Chrome requires returnValue to be set
        // e.returnValue = '';
        localStorage.setItem("edit_item", JSON.stringify(this.state.item));
    }

    removeStateFromLocal() {
        localStorage.removeItem("edit_item");
    }

    async componentDidMount() {

        const { match: { params } } = this.props;
        this.state.supplier = params.supplier;
        this.state.group = params.group;
        const { data } = this.props.location;
        this.state.item = data != null ? data : JSON.parse(localStorage.getItem("edit_item")) || null;
        this.state.rate = this.state.item.rate;
        this.state.moq = this.state.item.qty;
        this.setState({});
        window.addEventListener(
            "beforeunload",

            this.saveStateToLocalStorage.bind(this)
        );
        // await axios.get(`${url}supplier/groupIts`, {
        //   params: {
        //     'key': authenticationService.getAuthToken(),
        //     "supplier": params.supplier, 'groupId': params.id
        //   }
        // }).then(handleResponse)
        //   .then(res => {
        //     this.setState({
        //       items: res,
        //     });
        //   });

    }

    deleteImage(item) {


        this.setState({
            show: true,
            delImage: item
        });

    }

    showAlert() {
        console.log(JSON.stringify(this.state.delImage));
        this.setState({
            show: false,

        });
        this.deleteImageFromNetwork(this.state.delImage);
    }

    async deleteImageFromNetwork(image) {
        await axios.delete(`${url}supplier/image/${image.id}`, {
            params: {
                'key': authenticationService.getAuthToken(),
                "supplier": this.state.supplier
            }
        }).then(handleResponse)
            .then(res => {
                console.log(res);
                if (res == null) {
                    return;
                }
                store.addNotification({
                    title: "Success!",
                    message: "Image Deleted",
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
                const images = this.state.item.images.filter(i => i.id !== image.id);
                this.state.item.images = images;
                this.setState({
                    delImage: null
                });
            });
    }

    handleClose() {
        this.setState({
            show: false,
            delImage: null
        });
    }

    componentWillUnmount() {

        window.removeEventListener(
            "beforeunload",
            this.saveStateToLocalStorage.bind(this)
        );

        // // saves if component has a chance to unmount
        this.removeStateFromLocal();
    }

    changeRate(value) {
        this.setState({
            rate: value
        })
    }

    changeMoq(value) {
        this.setState({
            moq: value
        })
    }

    async update() {
        var rate = this.state.rate === this.state.item.rate ? -1 : this.state.rate;
        var moq = this.state.moq === this.state.item.qty ? -1 : this.state.moq;
        let supplier = this.state.supplier;
        if(rate === -1 && moq === -1){
            store.addNotification({
                title: "Info!",
                message: "No Data to update. Please change rate Or MOQ",
                type: "info",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 5000,
                    onScreen: true
                }
            });
            return;
        }
        await axios.post(`${url}supplier/item/${this.state.item.item_id}/${this.state.item.tranId}`, null, {
            params: {
                'key': authenticationService.getAuthToken(),
                "supplier": parseInt(supplier),
                "rate": rate,
                "moq": moq
            }
        }).then(handleResponse)
            .then(res => {

                store.addNotification({
                    title: "Success!",
                    message: "Item Detail Update Success",
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
            }, error => {
                // setSubmitting(false);
                // setStatus(error);
                store.addNotification({
                    title: "Error!",
                    message: error.response.data.error || "Error on Update Record",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });

            });
    }

    render() {


        // specify upload params and url for your files
        const getUploadParams = ({ meta }) => {
            const url = `${urlVal}supplier/upload/${this.state.item.tranId}`;
            // const url = "https://httpbin.org/post";
            return { url, fields: { 'supplier': this.state.supplier, 'key': authenticationService.getAuthToken() } }
        }

        // called every time a file's `status` changes
        const handleChangeStatus = ({ meta, file, xhr }, status) => {

            // console.log(status, meta, file);

            if (status === 'done' && xhr.response) {
                // console.log(meta);
                if (xhr.response == null) {
                    return;
                }
                let response = JSON.parse(xhr.response);
                let images = this.state.item.images;
                if (images) {
                    images.push({
                        id: response.id,
                        path: response.path
                    })
                } else {
                    images = [{
                        id: response.id,
                        path: response.path
                    }]
                }
                this.state.item.images = images;
                this.setState({});
                store.addNotification({
                    title: "Success!",
                    message: "Image upload Complete",
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
            }

        }

        // receives array of files that are done uploading when submit button is clicked
        const handleSubmit = (files, allFiles) => {
            // console.log(files.map(f => f.meta))
            allFiles.forEach(f => f.remove())
        }

        return (
            <div>
                <div className="container">
                    <div className="row">
                        <div className="container">
                            <ol className="breadcrumb breadcrumb-arrow">
                                <li><Link to={`/`}> Home </Link> </li>
                                <li> <Link to={`/group/${this.state.supplier}`}> Group </Link> </li>
                                <li><Link to={`/group/${this.state.supplier}/${this.state.group}`}> Items </Link> </li>
                                <li className="active"><span>Edit</span></li>
                            </ol>
                        </div>
                    </div></div>

                {this.state.item === null && <p>Loading Item...</p>}

                {this.state.item &&
                    <div>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    <Dropzone
                                        getUploadParams={getUploadParams}
                                        onChangeStatus={handleChangeStatus}
                                        onSubmit={handleSubmit}
                                        accept="image/*"
                                    />
                                </div>
                            </div></div>
                        <br />
                        <Bootbox
                            message="Do you want to Delete?"
                            show={this.state.show}
                            onYesClick={this.showAlert.bind(this)}
                            onNoClick={this.handleClose.bind(this)}
                            onClose={this.handleClose.bind(this)} />
                        <div className="container">
                            <span className="small" > 0 (Zero) Rate or MOQ items will not show in Passbook </span>
                            <div className="row">

                                <div className="col-md-4">
                                    <div className="input-group mb-3 input-group-sm">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">Rate</span>
                                        </div>
                                        <input type="number" className="form-control"
                                            value={this.state.rate} onChange={(e) => { this.changeRate(e.target.value) }} />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="input-group mb-3 input-group-sm">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">MOQ</span>
                                        </div>
                                        <input type="number" className="form-control"
                                            value={this.state.moq} onChange={(e) => { this.changeMoq(e.target.value) }} />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <button type="button" className="btn btn-outline-success btn-sm" onClick={this.update.bind(this)} > Update </button>
                                </div>



                            </div>

                            <br />

                            <div className="row">
                                {this.state.item.images && this.state.item.images.map(image => (
                                    <div className="col-md-3 col-sm-6" key={image.id}>
                                        <div className="img-container" >
                                            <img src={image.path} alt="item" className="rounded h-50 w-100"></img>

                                            <a onClick={this.deleteImage.bind(this, image)} className="icon" title="Delete Image">
                                                <div className="overlay">

                                                    <i className="fa fa-user">&times;</i>

                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                ))
                                }
                            </div>
                        </div>

                    </div>


                }

            </div>

        );
    }
}

export default EditItem;