import React from 'react';
import LoginPage from './LoginPage';
import { withRouter } from 'react-router-dom';

class LoginView extends React.Component {

    render() {
        return (
            <div className="container ">
                <div className="row justify-content-center">
                    <div className="col-md-6 col-sm-12">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Login</h5>
                                 <LoginPage />
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        );
    }
}


export default withRouter(LoginView)